<template>
<!-- Courses Video Playlist Start -->
<div class="courses-video-playlist">
    <div class="playlist-title">
        <h3 class="title">Course Content</h3>
        <span>80 Lessons (8h 15m)</span>
    </div>

    <!-- Video Playlist Start  -->
    <div class="video-playlist">
        <div class="accordion" id="videoPlaylist">
            <SinglePlaylist v-for="(Lesson,index) in Lessons" :key="Lesson" :Lesson="Lesson" :index="index + 1" @newVideo="newVideo" />
        </div>
    </div>
    <!-- Video Playlist End  -->
</div>
</template>
<script>
import SinglePlaylist from '@/components/CourseDetails/SinglePlaylist.vue'
export default{
    name:"VideoPlaylist",
    components:{
        SinglePlaylist
    },
    props:['Lessons'],
    methods:{
        newVideo(link){
            this.$emit('newVideo',link)
        }
    }
}
</script>