<template>
<!-- Accordion Items Start  -->
<div class="accordion-item">
    <button class="collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`">
        <p>Lesson-{{ index }} : {{ Lesson.name }}</p>
        <span class="total-duration">{{ Lesson.duration }}</span>
    </button>
    <div :id="`collapse${index}`" class="accordion-collapse collapse" data-bs-parent="#videoPlaylist">
        <nav class="vids">
            <a v-for="(SubLesson,index) in Lesson.sublesson" :key="SubLesson" @click="addActive(SubLesson.video)" class="link" >
                <p class="pointer">{{ index + 1 }}. {{ SubLesson.name }}</p>
                <span class="total-duration">{{ SubLesson.duration }}</span>
            </a>
        </nav>
    </div>
</div>
<!-- Accordion Items End  --> 
</template>
<script>
export default{
    name:"SinglePlaylist",
    props:["Lesson","index"],
    methods:{
        addActive(link){
            let lists = document.querySelectorAll(".link");
            lists.forEach((list) => {
                list.classList.remove("active");
            });
            event.target.classList.add("active");
            this.emitVideo(link);
        },
        emitVideo(link){
            this.$emit('newVideo',link)
        }
    }
}
</script>
<style>
.pointer{
    cursor:default;
    pointer-events: none;
}

</style>