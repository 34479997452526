<template>
<!-- Courses Video Container Start -->
<div class="vidcontainer ">
    <video id="player" playsinline controls data-poster="https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940">
        <source :src="Videolink" type="video/mp4" />
    </video>
</div>
<!-- Courses Video Container End -->
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
    export default{
        name:"VideoPlayer",
        props:["Videolink"],
        methods:{
            playVideo(){
                const player = new Plyr('#player');
            }   
        },
        mounted(){
            this.playVideo();
        },
        watch: {
            Videolink: function() {
                let src = this.Videolink;
                player.source = {
                    type: 'video',
                    title: 'Example title',
                    sources: [
                        {
                            src: src,
                            type: 'video/mp4'
                        }
                    ],
                    poster: "https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                };   
                console.log(player)
                player.play();
            }
        }
    }
</script>
<style>
:root {
  --plyr-color-main: #000080 ;
}
</style>