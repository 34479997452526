<template>
<!-- Description Start -->
<div class="description">
    <div class="row">
        <div class="col-lg-4">
            <div class="enroll-tab-title">
                <h3 class="title">Description</h3>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="enroll-tab-content">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the 1500s when andom unknown printer took a galley of type scrambled it to make a type specimen book. It has survived not’s only and five centuries, but also the lea into electronic typesetting, remaining priting essentially unchanged. It was popularsed in the 1960 with containing Lorem Ipsum passages desktop publishing software.</p>

                <p class="text">“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has industry's standard dummy text ever since the 1500s when andom unknown printer took a galley scrambled it to make a type specimen book.”</p>

                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the 1500s when andom unknown printer took a galley of type scrambled it to make a type specimen book. It has survived not’s only and five centuries, but also the lea into electronic typesetting, remaining priting essentially unchanged. It was popularsed in the 1960 with containing Lorem Ipsum passages desktop publishing software.</p>
            </div>
        </div>
    </div>
</div>
<!-- Description End -->
</template>
<script>

export default{
    name:"Description"
}
</script>