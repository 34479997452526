<template>
    <Header/>
    <!-- Courses Enroll Start -->
        <div class="section">

            <!-- Courses Enroll Wrapper Start -->
            <div class="courses-enroll-wrapper">
            
                <!-- Courses Video Player Start -->
                <div class="courses-video-player">

                    <VideoPlayer :Videolink="Videolink" />
                    
                    <!-- Courses Enroll Content Start -->
                    <div class="courses-enroll-content">

                        <!-- Courses Enroll Title Start -->
                        <div class="courses-enroll-title">
                            <h2 class="title">{{ CourseDetails.name }}</h2>
                            <p><i class="icofont-eye-alt"></i> <span>{{ CourseDetails.registerStudents }}</span> Students are watching</p>
                        </div>
                        <!-- Courses Enroll Title End -->

                        <!-- Courses Enroll Tab Start -->
                        <div class="courses-enroll-tab">
                            <div class="enroll-tab-menu">
                                <ul class="nav">
                                    <li><button class="active" data-bs-toggle="tab" data-bs-target="#tab1">Overview</button></li>
                                    <li><button data-bs-toggle="tab" data-bs-target="#tab2">Description</button></li>
                                    <li><button data-bs-toggle="tab" data-bs-target="#tab3">Certificates</button></li>
                                    <li><button data-bs-toggle="tab" data-bs-target="#tab4">Instructor</button></li>
                                </ul>
                            </div>
                            <div class="enroll-share">
                                <a href="#"><i class="icofont-share-alt"></i> Share</a>
                            </div>
                        </div>
                        <!-- Courses Enroll Tab End -->

                        <!-- Courses Enroll Tab Content Start -->
                        <div class="courses-enroll-tab-content">
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="tab1">

                                    <Overview/>
                                    

                                </div>
                                <div class="tab-pane fade" id="tab2">

                                    <Description/>
                                </div>
                                <div class="tab-pane fade" id="tab3">

                                    <!-- Certificates Start -->
                                    <div class="certificates">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="enroll-tab-title">
                                                    <h3 class="title">EduLe Certificates</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="enroll-tab-content">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the 1500s when andom unknown printer took a galley of type scrambled it to make a type specimen book. It has survived not’s only and five centuries, but also the lea into electronic typesetting, remaining priting essentially unchanged. It was popularsed in the 1960 with containing Lorem Ipsum passages desktop publishing software.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Certificates End -->

                                </div>
                                <div class="tab-pane fade" id="tab4">

                                    <!-- Instructor Start -->
                                    <div class="instructor">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="enroll-tab-title">
                                                    <h3 class="title">Instructor</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="enroll-tab-content">

                                                    <!-- Single Instructor Start -->
                                                    <div class="single-instructor">
                                                        <div class="review-author">
                                                            <div class="author-thumb">
                                                                <img src="assets/images/author/author-06.jpg" alt="Author">
                                                            </div>
                                                            <div class="author-content">
                                                                <h4 class="name">Sara Alexander</h4>
                                                                <span class="designation">Product Designer, USA</span>
                                                                <span class="rating-star">
																		<span class="rating-bar" style="width: 80%;"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the 1500s when andom unknown printer took a galley of type scrambled it to make a type specimen book.</p>
                                                    </div>
                                                    <!-- Single Instructor End -->

                                                    <!-- Single Instructor Start -->
                                                    <div class="single-instructor">
                                                        <div class="review-author">
                                                            <div class="author-thumb">
                                                                <img src="assets/images/author/author-07.jpg" alt="Author">
                                                            </div>
                                                            <div class="author-content">
                                                                <h4 class="name">Karol Bachman</h4>
                                                                <span class="designation">Product Designer, USA</span>
                                                                <span class="rating-star">
																		<span class="rating-bar" style="width: 80%;"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the 1500s when andom unknown printer took a galley of type scrambled it to make a type specimen book.</p>
                                                    </div>
                                                    <!-- Single Instructor End -->

                                                    <!-- Single Instructor Start -->
                                                    <div class="single-instructor">
                                                        <div class="review-author">
                                                            <div class="author-thumb">
                                                                <img src="assets/images/author/author-03.jpg" alt="Author">
                                                            </div>
                                                            <div class="author-content">
                                                                <h4 class="name">Gertude Culbertson</h4>
                                                                <span class="designation">Product Designer, USA</span>
                                                                <span class="rating-star">
																		<span class="rating-bar" style="width: 80%;"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the 1500s when andom unknown printer took a galley of type scrambled it to make a type specimen book.</p>
                                                    </div>
                                                    <!-- Single Instructor End -->

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Instructor End -->

                                </div>
                            </div>
                        </div>
                        <!-- Courses Enroll Tab Content End -->

                    </div>
                    <!-- Courses Enroll Content End -->
                </div>
                <!-- Courses Video Player End -->
                <VideoPlaylist :Lessons="Lessons" @newVideo="playVideo"/>
                <!-- Courses Video Playlist End -->

            </div>
            <!-- Courses Enroll Wrapper End -->

        </div>
        <!-- Courses Enroll End -->
        <CTA/>
    <Footer/>
</template>
<script>
import Header from '@/components/Instructors/Header.vue';
import Overview from '@/components/CourseDetails/Overview.vue';
import VideoPlayer from '@/components/CourseDetails/VideoPlayer.vue';
import Description from '@/components/CourseDetails/Description.vue';
import VideoPlaylist from '@/components/CourseDetails/VideoPlaylist.vue';
import CTA from '@/components/CTA.vue';
import Footer from '@/components/Footer.vue';
export default{
    name:"MyCourse",
    components:{
        Header,VideoPlaylist,Overview,VideoPlayer,Description,Footer,CTA
    },
    data(){
        return{
            Videolink:"",
            Lessons:[
                {name:"How to Get Good in the Market (intro)",sublesson:[
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood 2",video:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                ]},
                {name:"How to Get Good in the Market",sublesson:[
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                ]},
                {name:"How to Get Good in the Market",sublesson:[
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                ]},
                {name:"How to Get Good in the Market",sublesson:[
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                ]},
                {name:"How to Get Good in the Market",sublesson:[
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                    {name:"Only pick yellow wood",video:"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4"},
                ]},
            ],
            CourseDetails:{
                name:"Plumbing made eazy",
                registerStudents:"3,400"
            }
        }
    },
    methods:{
        playVideo(videolink){
            this.Videolink = (videolink) ? videolink : this.Lessons[0].sublesson[0].video
        }   
    },
    mounted(){
        this.playVideo();
    }
}
</script>